import type { VNode } from 'vue';
import MainFooter from '@/components/MainFooter';
import MainHeader from '@/components/MainHeader';
import NavBarMinimal from '@/components/NavBarMinimal';
import StickyBars from '@/components/StickyBars';

export default defineNuxtComponent({
  name: 'MinimalLayout',
  setup() {
    useHead({
      htmlAttrs: {
        'data-layout': 'minimal',
      },
    });
  },
  render(): VNode {
    return (
      <>
        <MainHeader>
          <NavBarMinimal />
        </MainHeader>
        <StickyBars />
        <div>{this.$slots.default?.()}</div>
        <MainFooter />
      </>
    );
  },
});
